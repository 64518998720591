import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Hero from '../components/Hero'
import Slices from '../components/Slices'
import Separator from '../components/Separator'
import Infographic from '../components/Infographic'

export const query = graphql`
  query PhilosophyQuery($lang: String) {
    prismic {
      data: allPhilosophys(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            infographicHeading: infographic_heading
            infographicBody: infographic_body
            infographicLink: infographic_link {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__Document {
                meta: _meta {
                  id
                  uid
                  type
                  lang
                }
              }
            }
            infographicLinkLabel: infographic_link_label
            infographic {
              number
              description
            }
            slices {
              ... on PRISMIC_PhilosophySlicesText {
                type
                primary {
                  body
                }
              }
              ... on PRISMIC_PhilosophySlicesBody {
                type
                primary {
                  heading
                  sticky
                  body
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        uid
                        type
                        lang
                      }
                    }
                  }
                  linkLabel: link_label
                  image1
                  image1Sharp: image1Sharp {
                    childImageSharp {
                      fluid(maxWidth: 850) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  image2
                  image2Sharp: image2Sharp {
                    childImageSharp {
                      fluid(maxWidth: 850) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageContainer = (props) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  return (
    <>
      <Hero {...data} />
      <Slices {...data} isSticky />
      <Infographic {...data} />
    </>
  )
}

// PageContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default PageContainer
