import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import ButtonLink from '../ButtonLink'
// import Wysiwyg from '../Wysiwyg'
import ColorChangingBackground from '../ColorChangingBackground'

// component
// ==========================================================================================
const Infographic = ({
  infographicHeading,
  // infographicBody,
  infographicLink,
  infographicLinkLabel,
  infographic = [],
}) => {
  const hasCta = infographicLinkLabel && infographicLink
  const filteredInfo = infographic.filter(
    (info) => info && info.number && info.description
  )

  return filteredInfo.length ? (
    <ColorChangingBackground className={css.wrap} noFadeOut>
      <div className="row">
        <div className="col-sm-12 col-md-9 col-lg-4 offset-md-1 position-static">
          <div className={css.titleWrap}>
            <Title tag="h2" content={infographicHeading} />
          </div>
          {/* <Wysiwyg content={infographicBody} /> */}
          {hasCta && (
            <div className={classNames(css.ctaWrap, css.cta1)}>
              <ButtonLink {...infographicLink}>
                {infographicLinkLabel}
              </ButtonLink>
            </div>
          )}
        </div>
        <div className="col-sm-12 col-md-9 col-lg-5 offset-md-1">
          {filteredInfo.map(({ number, description }) => (
            <div className={css.infoWrap} key={description}>
              <span className={css.number}>{number}</span>
              <span className="biggest">{description}</span>
            </div>
          ))}
          {hasCta && (
            <div className={classNames(css.ctaWrap, css.cta2)}>
              <ButtonLink {...infographicLink}>
                {infographicLinkLabel}
              </ButtonLink>
            </div>
          )}
        </div>
      </div>
    </ColorChangingBackground>
  ) : null
}

export default Infographic
